import { helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import { SubscriptionPlanCurrency, SubscriptionPlanPeriod } from "@/enums";
import subscriptionPlanHttp from "@/http/subscriptionPlan";
export default defineComponent({
    components: {
        BaseSelect,
        BaseInput,
        TeleportToContentFooter,
        BaseFormGroup,
        BaseFormApp,
        BaseFileInput,
    },
    mixins: [
        helperMixin,
        saveAndUpdate(subscriptionPlanHttp()),
    ],
    data() {
        return {
            features: [],
            periods: [
                {
                    default: true,
                    text: SubscriptionPlanPeriod.month,
                    value: SubscriptionPlanPeriod.month,
                },
                {
                    text: SubscriptionPlanPeriod.year,
                    value: SubscriptionPlanPeriod.year,
                },
            ],
            currencies: [
                {
                    text: SubscriptionPlanCurrency.Dollar,
                    value: SubscriptionPlanCurrency.Dollar,
                },
                {
                    text: SubscriptionPlanCurrency.IDR,
                    value: SubscriptionPlanCurrency.IDR,
                },
            ],
            currentImageLink: "",
        };
    },
    methods: {
        onMounted() {
            subscriptionPlanHttp()
                .features()
                .then(({ response }) => {
                var _a, _b;
                this.features =
                    (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.map((row) => {
                        if (row.is_live && !this.isUpdateState) {
                            this.form.features.push(row.id);
                        }
                        return {
                            text: this.trans(`menu.${row.title}`),
                            value: row.id,
                            disabled: !row.is_live,
                        };
                    })) !== null && _b !== void 0 ? _b : [];
            });
        },
        setForm() {
            var _a, _b, _c;
            this.form = {
                device_limit: 1,
                ...((_a = this.data) !== null && _a !== void 0 ? _a : {}),
            };
            this.form.features =
                (_c = (_b = this.data) === null || _b === void 0 ? void 0 : _b.features.map((feature) => feature.company_feature_id)) !== null && _c !== void 0 ? _c : [];
            this.form.price_currency = SubscriptionPlanCurrency.IDR;
        },
    },
});
